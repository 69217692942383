.main {
    max-width:1200px;
    position:relative;
    margin:0 auto;
}

.tip {
    background-color:#fff;
    font-size:16px;
    padding:15px;
    border-radius:5px;
    margin-bottom:1.4%;
    font-family: "Noto Sans KR", sans-serif;
    font-weight:700;
    color:#000;
    em {
        margin-right:5px;
    }
}

.guaranteeList {
 display:inline-block;
 width:100%;
    > div {
        display:inline-block;
        width:32.333%;
        margin-left:1.5%;
        vertical-align: top;
        &:first-child{margin-left:0}
        
        .ant-card-head {
            padding:0 18px
        }
        .ant-card-head-title, .ant-card-body {
            font-family: "Noto Sans KR", sans-serif;
        }
        .ant-card-body {
            height:110px;
            padding:16px 18px;
        }
        
    }
    @media (max-width:750px) {
        > div {
            width:100%;
            margin:12px 0;
            &:first-child{
                margin-top:0;
            }
        }   
    }
}