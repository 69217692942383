#header {
    position:fixed;
    width:100%;
    height:48px;
    top:0;
    left:0;
    border-bottom:1px solid #d9d9d9;
    background-color:#fff;
    z-index:1;
    
    h1 {
        position:absolute;
        left:11px;
        top:7px;
        a {
            font-family: "Noto Sans KR", sans-serif;
            font-weight:900;
            font-size:0.6em;
            color:#7c6bfd;
        }
    }

    .util {
        position:absolute;
        right:11px;
        top:12px;
        li {
            display:inline-block;
            a {
                font-size:0.8em;

            }
        }
    }
}